import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import App from './App';
import './styles/index.scss';
import { BACKEND_API_URL, CLIENT_ID, TENANT } from './app.constant';
import { type IAppConfig } from './models/IAppConfig';
import { initializeSession } from './services/session.service';
import { initConfig } from './AppConfig';
import { initApi } from './services/api.service';
import configureMsalAuthProvider from './services/msalConfig';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { store } from './store';
import { AzureAD, AuthenticationState } from 'react-aad-msal';
import { type IAuthProvider } from 'react-aad-msal/dist/typings/interfaces';
import LoadIndicator from './components/common/loadIndicator/LoadIndicator';
import { sha256 } from 'js-sha256';
import * as microsoftTeams from '@microsoft/teams-js';
import { isTeamsApp, routeToLogin } from './utils/commonUtils';
import { setUSerEmail } from './store/actions/commonActions';
import { jwtDecode } from 'jwt-decode';
import { storeTeamsUserProfile } from './store/actions/userProfileActions';
declare let pendo: any;
const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
const Root: React.FC = () => (
  <Provider store={store}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Provider>
);

window.addEventListener('DOMContentLoaded', () => {
  const settingsEl = document.getElementById('settings');
  const appConfig: IAppConfig = {
    activeDirectory: {
      tenant: settingsEl === null ? TENANT : settingsEl.getAttribute('data-tenant')!,
      clientId: settingsEl === null ? CLIENT_ID : settingsEl.getAttribute('data-client-id')!
    },
    baseApiUrl: BACKEND_API_URL,
    azureMapsClientId: ''
  };

  initializeSession();
  initConfig(appConfig);
  initApi(appConfig);
  localStorage.setItem('acceptPolicy', 'false');
  const urlParams = new URLSearchParams(window.location.search);
  const param = urlParams.get('q');
  const searchObj = param ? JSON.parse(param) : '';

  const msalAuthFlow = () => {
    void configureMsalAuthProvider(appConfig.activeDirectory, searchObj).then((result) => {
      root.render(
        // @ts-expect-error - AzureAD typings are not up to date
        <AzureAD provider={result}>
          {({ login, authenticationState, accountInfo }: any): JSX.Element => {
            switch (authenticationState) {
              case AuthenticationState.Authenticated:
                localStorage.setItem('accountName', accountInfo.account.name);
                localStorage.setItem('accountEmail', accountInfo.account.userName);
                // initializePendo();
                window.digitaldata.user.userId = sha256(accountInfo.account.userName.toLowerCase());
                return <Root />;

              case AuthenticationState.Unauthenticated:
                login();
                return <></>;

              case AuthenticationState.InProgress:
                return (
                  <div className='global-loder'>
                    <div className='center-wrapper'>
                      <LoadIndicator />
                    </div>
                  </div>
                );

              default:
                return <Root />;
            }
          }}
        </AzureAD>
      );
    });
  };

  const teamsAuthFlow = () => {
    microsoftTeams.app
      .initialize()
      .then(() => {
        microsoftTeams.app
          .getContext()
          .then((context) => {
            if (context) {
              microsoftTeams.authentication
                .getAuthToken()
                .then((token) => {
                  localStorage.setItem('teamsAuthToken', token);
                  const userObject: any = jwtDecode(token);
                  store.dispatch(setUSerEmail(userObject?.preferred_username));
                  store.dispatch(storeTeamsUserProfile({ ...userObject, rawIdToken: token}));
                  localStorage.setItem('accountName',  userObject?.name);
                  localStorage.setItem('accountEmail', userObject?.preferred_username);
                  root.render(<Root />);
                })
                .catch((error) => {
                  routeToLogin();
                  return <></>;
                });
            } else console.log('Context not found');
          })
          .catch((error) => {
            console.error('Get Context Error', error);
          });
      })
      .catch((error) => {
        console.error('Teams Initialization Error', error);
      });
  };

  if (isTeamsApp()) {
    teamsAuthFlow();
  } else {
    msalAuthFlow();
  }
 });
 
 
function initializePendo() {
  // This function creates visitors and accounts in Pendo
    // You will need to replace <visitor-id-goes-here> and <account-id-goes-here> with values you use in your app
    // Please use Strings, Numbers, or Bools for value types.
    pendo.initialize({
      visitor: {
          id:"Akhil" // Required if user is logged in
          // email:        // Recommended if using Pendo Feedback, or NPS Email
          // full_name:    // Recommended if using Pendo Feedback
          // role:         // Optional

          // You can add any additional visitor level key-values here,
          // as long as it's not one of the above reserved names.
      },

      account: {
          id:              "Akhil" // Highly recommended, required if using Pendo Feedback
          // name:         // Optional
          // is_paying:    // Recommended if using Pendo Feedback
          // monthly_value:// Recommended if using Pendo Feedback
          // planLevel:    // Optional
          // planPrice:    // Optional
          // creationDate: // Optional

          // You can add any additional account level key-values here,
          // as long as it's not one of the above reserved names.
      }
  });
}

