import { fireViewEndCustomEvent } from './adobeAnalyticsEvents';
import { adobeLaunchURL, payload } from './config';
import { digitaldata } from "./config";
import $ from 'jquery';


declare const _satellite: any;

export const setPageInDigitalData = (dataLayerObj: any) => {
  digitaldata['page'] = {
    ...digitaldata['page'],
    ...dataLayerObj['page'],
  };
};

export const setPageNameObj = (dataLayerObj: any, valToSet: string) => {
  dataLayerObj['page']['pageName'] = valToSet;
  return dataLayerObj;
};


// attach analytics script to head tag of SPA
const createAnalyticsScript = () => {
  let scriptLaunchTag = document.createElement('script');
  scriptLaunchTag.type = 'text/javascript';
  scriptLaunchTag.src = adobeLaunchURL;
  scriptLaunchTag.async = true;
  scriptLaunchTag.setAttribute('id', 'scriptLaunchTag');
  document.getElementsByTagName('head')[0].appendChild(scriptLaunchTag);
};

// edit digital data based on data received from component
const mutateDigitaldata = (dataLayerObjStringified: string) => {
  let dataLayerObj = JSON.parse(dataLayerObjStringified);
  dataLayerObj = setPageNameObj(dataLayerObj, dataLayerObj['page']['pageName']);
  setPageInDigitalData(dataLayerObj);
  // fireViewEndCustomEvent();
};

export const executeAnalytics = (dataLayerObj: object) => {
  const dataLayerObjStringified = JSON.stringify(dataLayerObj);
  mutateDigitaldata(dataLayerObjStringified);

  const elementExists = document.getElementById('scriptLaunchTag');
  if (!elementExists) {
    createAnalyticsScript();
  }
  //console.log("page reload hit",window.digitaldata.user.userId);
  fireViewEndCustomEvent();
};

// removes query parameters - use this if the project has hash router

export const getLastUri = (url_param: string) => {
  let url = new URL(decodeURIComponent(url_param));
  url.search = '';
  const processed_Url = url.toString().split('/');
  let lastUri = processed_Url[processed_Url.length - 1];
  let processedUri =
    lastUri.indexOf('#') != -1 ? lastUri.split('#')[0] : lastUri;
    if (processedUri=="") {
     return 'Home';
    }
  return processedUri;
};



////// Generic link click Tracking ////////

const linkClickTracking = (linkName: string) => {
  if (typeof _satellite !== 'undefined') {
    digitaldata.onclick.linkName = linkName;
    _satellite.track('Link Tracking');
    digitaldata.onclick.linkName = '';
  }
};

$(document).on('mousedown', 'a', (e: { currentTarget: any; }) => {
  let linkLabel = $(e.currentTarget).text();
  if (linkLabel !== '') {
    linkClickTracking(linkLabel);
  }
});



