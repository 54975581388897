import { PublicClientApplication, EventType } from "@azure/msal-browser";
import { msalConfig } from "../config/authConfig";
import CommonService from "./commonService.js";
import { authProvider } from './msalConfig';
import { getSharePointTeamsToken, isTeamsApp } from '../utils/commonUtils';
import {getFirstFolderName} from "../utils/commonUtils";


let msalInstance;
if (!msalInstance) {
  msalInstance = new PublicClientApplication(msalConfig);
}

class GraphService {
  constructor(msalInstance) {
    this.commonService = new CommonService();
    this.graphApiBaseUrl = "https://graph.microsoft.com/v1.0";
    this.accessToken = "";
    this.msalInstance = msalInstance;
  }
  // Get latest token from Msal
  async getToken() {
    try {
      const tokenResponse = await authProvider.getAccessToken();
      this.accessToken = tokenResponse.accessToken;

      return tokenResponse.accessToken;
    } catch (error) {
      console.error("Failed to acquire token silently:", error);
    }
  }

  resetToken() {
    this.accessToken = null;
  }

  // Get logged in user Groups
  async getUserDetails() {
    await this.getToken();
    const graphApiEndPoint = `${this.graphApiBaseUrl}/me`;

    const requestOptions = {
      method: "GET",
      headers: {
        Authorization: `Bearer ${this.accessToken}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };
    try {
      const response = await fetch(graphApiEndPoint, requestOptions);
      if (!response.ok) {
        throw new Error(`Http error Status :${response.status}`);
      }
      return await response.json();
    } catch (error) {
      this.commonService.logException(
        error,
        "Application",
        "Get User Details"
      );
    }
  }
  //Gets user details based on email from Azure
  async getUserDetailsByEmail(emailid) {
    await this.getToken();
    const graphApiEndPoint = `${this.graphApiBaseUrl}/users?$filter=mail eq '${emailid}'`;

    const requestOptions = {
      method: "GET",
      headers: {
        Authorization: `Bearer ${this.accessToken}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };
    const response = await fetch(graphApiEndPoint, requestOptions);
    if (!response.ok) {
      throw new Error(`Http error Status :${response.status}`);
    }
    return await response.json();
  }
  //Gets user details based on email from Azure
  async getUserDetailsByInput(inputdata) {
    await this.getToken();
    const graphApiEndPoint = `${this.graphApiBaseUrl}/users?$filter=mail eq '${inputdata}'`;
    const requestOptions = {
      method: "GET",
      headers: {
        Authorization: `Bearer ${this.accessToken}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };
    const response = await fetch(graphApiEndPoint, requestOptions);
    if (!response.ok) {
      throw new Error(`Http error Status :${response.status}`);
    }
    const data = await response.json();
    return data;
  }
  //Gets sharepoint details
  async getSharePointSiteDetails(doamin, link) {
    return this.sharePointEndpoint(
      `${this.graphApiBaseUrl}/sites/${doamin}:/${link}`
    );
  }

  async getSharePointDocumentFiles(siteId, path) {
    return this.sharePointEndpoint(
      `${this.graphApiBaseUrl}/sites/${siteId}/drive/root:/${getFirstFolderName(path)}:/children`
    );
  }

  async sharePointEndpoint(url) {
    await this.getToken();
    let errorResponse = {
      isError: true,
      message: "",
      status: 0,
    };
    const requestOptions = {
      method: "GET",
      headers: {
        Authorization: isTeamsApp() ? `Bearer ${await getSharePointTeamsToken()}` : `Bearer ${this.accessToken}`,
        Accept: "application/json",
        "Content-Type": "application/json",
        'IsMSTeamsClient' : isTeamsApp() ? 'true' : 'false'
      },
    };
    try {
      const response = await fetch(url, requestOptions);
      if (!response.ok) {
        errorResponse.status = response.status;
        throw new Error(`Http error Status :${response.status}`);
      }
      return await response.json();
    } catch (error) {
      errorResponse.message = error;
      this.commonService.logException(
        error,
        "Upload Document",
        "Get Sharepoint Details"
      );
      return errorResponse;
    }
  }

  async getSharePointDocumentDetails(siteId, path) {
    await this.getToken();
    const graphApiEndPoint = `${this.graphApiBaseUrl}/sites/${siteId}/drive/root:${path}`;

    const requestOptions = {
      method: "GET",
      headers: {
        Authorization: `Bearer ${this.accessToken}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };
    try {
      const response = await fetch(graphApiEndPoint, requestOptions);
      if (!response.ok) {
        throw new Error(`Http error Status :${response.status}`);
      }
      return await response.json();
    } catch (error) {
      console.error(error);
    }
  }

  // Add Other Methods
}

export default GraphService;